import styled from 'styled-components'
import colors from '../../colors'
import media from '../ui/mediaqueries'

export const ExplanationFooterWrapper = styled.div`
  background-color: ${colors.lightBlue};
  margin-top: 40px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    flex-shrink: 0;
    font-family: jamedaregular, sans-serif;
    font-size: 15px;
    margin: 0 50px 0 0;
  }

  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;

    > div {
      display: flex;
      flex-direction: column;
    }

    &:last-of-type {
      margin-right: 0;
    }

    a {
      display: inline-block;
      padding-right: 20px;
      flex-shrink: 0;
    }

    img,
    svg {
      flex-grow: 0;
      flex-shrink: 0;
      height: auto;
    }
  }

  h4,
  p {
    font-size: 13px;
    line-height: 15px;
    margin: 0;
  }

  h4 {
    margin-bottom: 15px;
  }

  ${media.tablet`
    flex-direction: column;
    text-align: center;
    padding: 50px 25px;

    h3 {
      margin: 0 0 6px 0;
    }

    > div {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 20px;

      a {
        margin: 20px 0;
      }
    }
  `}
`
