/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

import DeutschesRotesKreuz from '../../images/corona-drk.png'
import MsfLogo from '../../images/msf-logo.jpg'
import { ExplanationFooterWrapper } from './ui'

const ExplanationFooter = () => (
  <ExplanationFooterWrapper>
    <h3>Unsere Spende geht je zur Hälfte an</h3>
    <div>
      <a
        href="https://www.drk.de/spenden/corona-nothilfsfonds/"
        target="_blank"
      >
        <img
          src={DeutschesRotesKreuz}
          width="120px"
          alt="Deutsche Rotes Kreuz - Corona-Nothilfefonds"
        />
      </a>
      <div>
        <h4>Spende Fürsorge zum Corona-Nothilfefonds </h4>
        <p>
          Das DRK hilft seit Wochen bundesweit mit vielfältigen, gezielten
          Maßnahmen dabei, die Ausbreitung des Virus einzudämmen, das
          Gesundheitssystem zu stärken und Kliniken zu entlasten.
        </p>
      </div>
    </div>
    <div>
      <a href="https://www.aerzte-ohne-grenzen.de/" target="_blank">
        <img src={MsfLogo} width="120px" alt="Ärzte Ohne Grenzen Logo" />
      </a>
      <div>
        <h4>Ärzte ohne Grenzen</h4>
        <p>
          Sie sind in der aktuellen Coronakrise an den weltweiten Brennpunkten
          im Einsatz - überall dort, wo das nationale Gesundheitssystem nicht
          mehr in der Lage ist, den besonders schwer Betroffenen zu helfen.
        </p>
      </div>
    </div>
  </ExplanationFooterWrapper>
)

export default ExplanationFooter
