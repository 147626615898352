/* eslint-disable no-case-declarations */
import React, { useReducer } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import media from './ui/mediaqueries'
import states from '../states'

import Header from './Header'
import Footer from './Footer'
import GlobalStyles from './GlobalStyles'
import GermanyMap from './GermanyMap'
import ExplanationFooter from './ExplanationFooter'
import HeartContext from './HeartContext'

const PageWrapper = styled.div`
  min-height: calc(100vh - 292px);
  display: grid;
  grid-template-columns: 850px 591px auto;
  grid-template-areas: 'form map feed';

  ${media.desktop`
    grid-template-areas:
      'form map'
      'feed feed';
    grid-template-columns: 850px auto;
    grid-template-rows: auto auto;
  `}

  ${media.smallDesktop`
    grid-template-areas:
      'form'
      'map'
      'feed';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  `}
`

const FirstColumn = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  grid-area: form;
`

const initialState = {
  hearts: [],
  countPerState: states.reduce(
    (acc, state) => ({ ...acc, [state.short]: state.count }),
    {}
  ),
  totalCount: 140247,
}

const heartReducer = (state, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const Layout = ({ children }) => {
  const heartReducerResult = useReducer(heartReducer, initialState)

  return (
    <>
      <GlobalStyles />
      <HeartContext.Provider value={heartReducerResult}>
        <PageWrapper>
          <FirstColumn>
            <Header />
            <main>{children}</main>
          </FirstColumn>
          <GermanyMap />
        </PageWrapper>
        <ExplanationFooter />
        <Footer />
      </HeartContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Layout
