import styled from 'styled-components'

import colors from '../../colors'
import media from '../ui/mediaqueries'

export const StyledFooter = styled.footer`
  background-color: ${colors.white};
  display: flex;
  padding: 16px 50px;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.tablet`
    flex-direction: column;
    height: auto;
    padding: 0;
  `}
`

export const FooterInfo = styled.div`
  color: ${colors.mediumGray};
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    margin: 0 10px;
  }

  img,
  svg {
    height: 40px;
  }

  ${media.tablet`
    flex-direction: column;
    padding: 12px;

    span {
      margin-bottom: 10px;
    }

    a {
      margin: 5px 0;
    }

    img,
    svg {
      max-height: 24px;
    }
  `}
`

export const FooterLinks = styled.div`
  display: flex;
  align-items: center;

  ${media.tablet`
    width: 100%;
    padding: 12px;
    border-top: 1px solid ${colors.lightGray};
    justify-content: center;
  `}

  a {
    color: ${colors.darkerGray};
    text-decoration: none;
    margin-right: 31px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Disclaimer = styled.span`
  width: 100%;
  font-size: 11px;
  color: ${colors.mediumGray};
  ${media.tablet`
    text-align: center;
    margin-bottom: 24px;
  `}
`
