import React, { useRef, useContext } from 'react'

import getStatePinPosition from '../../utils/getStatePinPosition'
import delimitNumbers from '../../utils/delimitNumbers'
import { StyledPin } from './ui'
import HeartContext from '../HeartContext'

const Pin = ({ state }) => {
  const [{ countPerState }] = useContext(HeartContext)
  const {
    current: [x, y],
  } = useRef(getStatePinPosition(state))

  return (
    <StyledPin x={x} y={y}>
      {delimitNumbers(countPerState[state.short])}
    </StyledPin>
  )
}

export default Pin
