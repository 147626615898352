export default state => {
  switch (state.short) {
    case 'BW':
      return [35, 82]
    case 'BY':
      return [62, 79]
    case 'BE':
      return [82, 32]
    case 'BB':
      return [86, 41]
    case 'HB':
      return [33, 25]
    case 'HH':
      return [47, 19]
    case 'HE':
      return [35, 58]
    case 'MV':
      return [71, 16]
    case 'NI':
      return [42, 33]
    case 'NRW':
      return [21, 45]
    case 'RP':
      return [15, 65]
    case 'SL':
      return [10, 71]
    case 'SN':
      return [82, 53]
    case 'ST':
      return [63, 42]
    case 'SH':
      return [45, 12]
    case 'TH':
      return [57, 55]
    default:
      return [0, 0]
  }
}
