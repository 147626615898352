export default {
  primaryRed: '#F40D15',
  primaryBlue: '#01A3D4',

  secondary: '#8AD223',
  secondaryBlue: '#7DE7FF',

  lightBlue: '#DEF9FF',

  white: '#FFFFFF',
  mediumTransparentWhite: 'rgba(255,255,255,0.5)',
  lightGray: 'rgb(238, 242, 246)',
  translucentLightGray: 'rgb(238, 242, 246, 0)',
  lighterGray: '#DDDDDD',
  mediumGray: '#C0C7CE',
  darkerGray: '#95989A',
  darkGray: '#4D4D57',
  transparentLightBlack: 'rgba(0,0,0,0.29)',
}
