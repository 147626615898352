import styled from 'styled-components'

const PositionWrapper = styled.div`
  max-width: 100%;
  position: ${({ position }) => position || 'absolute'};
  ${({ top }) => top && `top: ${top};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ left }) => left && `left: ${left};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}
  ${({ transform }) => transform && `transform: ${transform};`}
  ${({ width }) => width && `width: ${width};`}
`

export default PositionWrapper
