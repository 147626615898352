import { css } from 'styled-components'

const breakpoints = {
  mobile: 780,
  tablet: 1250,
  smallDesktop: 1500,
  desktop: 1750
}

export default Object.keys(breakpoints).reduce(
  (functions, label) =>
    Object.assign(functions, {
      [label]: (...args) =>
        css`
          @media (max-width: ${breakpoints[label]}px) {
            ${css(...args)}
          }
        `.join('')
    }),
  {}
)
