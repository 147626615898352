/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

import JamedaLogo from '../../images/jameda-logo.svg'
import CoronaCareLogo from '../../images/corona-care-logo.svg'
import BunteDeLogo from '../../images/bunte_de_logo.svg'
import FocusOnlineLogo from '../../images/focus-online-logo.png'
import { StyledFooter, FooterInfo, FooterLinks, Disclaimer } from './ui'

const Footer = () => (
  <StyledFooter>
    <FooterInfo>
      <span>Eine Aktion von</span>
      <a href="https://www.jameda.de/" target="_blank">
        <JamedaLogo />
      </a>
      <span>in Zusammenarbeit mit</span>
      <a
        href="https://www.focus.de/perspektiven/deutschland-hilft-sich/"
        target="_blank"
      >
        <CoronaCareLogo />
      </a>
      <a href="https://www.focus.de/" target="_blank">
        <img src={FocusOnlineLogo} alt="Focus Online" />
      </a>
      <a href="https://www.bunte.de/" target="_blank">
        <BunteDeLogo />
      </a>
    </FooterInfo>
    <FooterLinks>
      <a href="https://www.jameda.de/jameda/datenschutz.php">Datenschutz</a>
      <a href="https://www.jameda.de/jameda/impressum.php">Impressum</a>
    </FooterLinks>
    <Disclaimer>Für diese Aktion beträgt das Spendenmaximum 20.000€</Disclaimer>
  </StyledFooter>
)

export default Footer
