import styled from 'styled-components'

import colors from '../../colors'
import media from '../ui/mediaqueries'

export const StyledGermanyWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 30px;

  ${media.mobile`
    margin-top: 40px;
    background-color: ${colors.white};
  `}
`

export const StyledGermanyInnerWrapper = styled.div`
  position: relative;
  width: 591px;
  max-width: 100%;
  max-height: 800px;

  grid-area: map;
  left: 50%;
  transform: translateX(-50%);

  svg {
    width: 100%;
    height: 100%;

    path {
      position: relative;
    }
  }

  ${media.mobile`
    left: 0;
    transform: unset;
    max-width: calc(100% - 64px);
    margin: 32px;
  `}
`

export const StyledPin = styled.span`
  position: absolute;
  top: ${({ y }) => y}%;
  left: ${({ x }) => x}%;
  transform: translate(-50%, calc(-100% - 12px));
  background-color: ${colors.white};
  box-shadow: 0 3px 6px ${colors.transparentLightBlack};
  color: ${colors.primaryRed};
  font-family: jamedamedium, sans-serif;
  padding: 8px 10px;
  border-radius: 4px;
  z-index: 1;

  &::before {
    z-index: 0;
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${colors.white};
    position: absolute;
    bottom: -6px;
    left: calc(50% - 6px);
    transform: rotate(45deg);
    box-shadow: 0 3px 6px ${colors.transparentLightBlack};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 11px;
    max-width: 80%;
    background-color: ${colors.white};
  }

  ${media.mobile`
    padding: 3px;
    font-size: 11px;
    line-height: 11px;

    &::before {
      width: 4px;
      height: 4px;
      bottom: -2px;
      left: calc(50% - 2px);
    }

    &::after {
      height: 4px;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
    }
  `}
`
