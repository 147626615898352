import React, { memo } from 'react'

import states from '../../states'
import GermanySvg from '../../images/map.svg'
import { StyledGermanyWrapper, StyledGermanyInnerWrapper } from './ui'
import Pin from './Pin'

const GermanyMap = () => (
  <StyledGermanyWrapper>
    <StyledGermanyInnerWrapper>
      {states.map(state => (
        <Pin state={state} key={state.short} />
      ))}
      <GermanySvg />
    </StyledGermanyInnerWrapper>
  </StyledGermanyWrapper>
)

export default memo(GermanyMap)
