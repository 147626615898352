export default [
  { name: 'Baden-Württemberg', short: 'BW', count: 13202 },
  { name: 'Bayern', short: 'BY', count: 28832 },
  { name: 'Berlin', short: 'BE', count: 43355 },
  { name: 'Brandenburg', short: 'BB', count: 2025 },
  { name: 'Bremen', short: 'HB', count: 578 },
  { name: 'Hamburg', short: 'HH', count: 3343 },
  { name: 'Hessen', short: 'HE', count: 6416 },
  { name: 'Mecklenburg-Vorpommern', short: 'MV', count: 1001 },
  { name: 'Niedersachsen', short: 'NI', count: 5432 },
  { name: 'Nordrhein-Westfalen', short: 'NRW', count: 17434 },
  { name: 'Rheinland-Pfalz', short: 'RP', count: 4176 },
  { name: 'Saarland', short: 'SL', count: 1073 },
  { name: 'Sachsen', short: 'SN', count: 3039 },
  { name: 'Sachsen-Anhalt', short: 'ST', count: 1458 },
  { name: 'Schleswig-Holstein', short: 'SH', count: 7315 },
  { name: 'Thüringen', short: 'TH', count: 1568 },
]
