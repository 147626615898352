import React from 'react'
import styled from 'styled-components'

import media from './mediaqueries'

import CoronaCareHerz from '../../images/corona-care-herz.svg'

const HeartWrapper = styled.a`
  display: block;
  width: 69px;
  height: 80px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.9)' : 'scale(1)')};

  ${media.mobile`
    width: 41px;
    height: 54px;
  `}
`

const HeartLogo = () => (
  <HeartWrapper href="/">
    <CoronaCareHerz />
  </HeartWrapper>
)

export default HeartLogo
