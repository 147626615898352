import React, { useContext } from 'react'

import HeartLogo from '../ui/HeartLogo'
import HeartContext from '../HeartContext'

import PositionWrapper from '../ui/PositionWrapper'
import {
  HeaderWrapper,
  HeaderInnerWrapper,
  ParticipantsWrapper,
  Participants,
} from './ui'
import delimitNumbers from '../../utils/delimitNumbers'

const Header = () => {
  const [{ totalCount: count }] = useContext(HeartContext)
  return (
    <HeaderWrapper>
      <HeaderInnerWrapper>
        <PositionWrapper position="relative" marginRight="20px">
          <HeartLogo />
        </PositionWrapper>
        <ParticipantsWrapper>
          <Participants>{delimitNumbers(count)} Herzen</Participants>
          <span>Spende: {delimitNumbers(Math.floor(count / 10))} €</span>
        </ParticipantsWrapper>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  )
}
export default Header
