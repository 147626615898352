import styled from 'styled-components'

import colors from '../../colors'
import media from '../ui/mediaqueries'
import PositionWrapper from '../ui/PositionWrapper'

export const HeaderWrapper = styled.header`
  position: relative;
  background: ${colors.lightGray};
  display: flex;
  padding: 40px 0;
  align-items: center;
  margin-right: 50px;

  ${media.mobile`
    padding: 0;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    margin-right: 0;

    > ${PositionWrapper} {
      left: 0;
      width: 100%;
      position: relative;
      margin-left: 0;
    }
  `}
`

export const HeaderInnerWrapper = styled.div`
  background: ${colors.primaryBlue};
  height: 120px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: 0 50px;
  box-shadow: 0 3px 6px ${colors.transparentLightBlack};

  ${media.mobile`
    z-index: 1;
    width: 100%;
    padding: 20px 10px;
    height: unset;
  `}
`

export const ParticipantsWrapper = styled.div`
  > span {
    font-family: jamedaregular, sans-serif;
    color: ${colors.mediumTransparentWhite};
    font-size: 20px;

    ${media.mobile`
      font-size: 15px;
    `}
  }
`
export const Participants = styled.h2`
  font-size: 40px;
  color: ${colors.white};
  margin-bottom: 10px;
  font-weight: normal;
  font-family: jamedaregular, sans-serif;

  ${media.mobile`
    font-size: 25px;
    margin-bottom: 5px;
  `}
`
